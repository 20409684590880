import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'container',
    'saveResultsSettingsButton',
    'cancelResultsSettingsButton',
    'pageSourceHiddenField',
    'votingResultsSettingsDataControllerHiddenField',
    'savingMessage'
  ]

  declare readonly containerTarget: HTMLDivElement
  declare readonly saveResultsSettingsButtonTarget: HTMLButtonElement
  declare readonly cancelResultsSettingsButtonTarget: HTMLButtonElement
  declare readonly pageSourceHiddenFieldTarget: HTMLInputElement
  declare readonly votingResultsSettingsDataControllerHiddenFieldTarget: HTMLInputElement
  declare readonly savingMessageTarget: HTMLDivElement

  saveResultsSettingsButton(event): void {
    let type = `${this.identifier}:saveSettings`

    this.element.dispatchEvent(new CustomEvent(type, { bubbles: true }))

    this.containerTarget.classList.add('hide')
    this.savingMessageTarget.classList.remove('hide')
    document
      .querySelector(`[data-controller="${this.votingResultsSettingsDataControllerHiddenFieldTarget.value}"]`)
      ?.querySelectorAll('input, select, textarea, button')
      ?.forEach(element => element.disabled = true)

    window.top.location = this.pageSourceHiddenFieldTarget.value
  }

  cancelResultsSettingsButton(event): void {
    NProgress.start()
    window.top.location = this.pageSourceHiddenFieldTarget.value
  }
}