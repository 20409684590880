import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ['addInfoButton'];

  declare addInfoButtonTarget: HTMLButtonElement;

  public writeInUpdate(event): void {
    const answerDraftId = event.target.getAttribute('data-answer-draft-id');
    const invitationKey = event.target.getAttribute('data-invitation-key');
    const questionId = event.target.getAttribute('data-question-id');
    const answerIndex = event.target.getAttribute('data-answer-index');
    const text = event.target.value;

    const data = {
      text: text,
      question_id: questionId,
      index: answerIndex
    };

    if (answerDraftId) {
      this.updateAnswer(answerDraftId, data, questionId, answerIndex);
    } else {
      this.createAnswer(invitationKey, data, questionId, answerIndex);
    }
  }

  public toggleInfoButton(event): void {
    const text = event.target.value.trim();

    if (text.length === 0) {
      this.addInfoButtonTarget.classList.add('disabled-button');
    } else {
      this.addInfoButtonTarget.classList.remove('disabled-button');
    }
  }

  private async createAnswer(invitationKey, data, questionId, answerIndex): void {
    const url = `/voters/${invitationKey}/answer_drafts`;
    const idToQuery = answerIndex ? `#question_${questionId}_add_profile_${answerIndex}` : `#question_${questionId}_add_profile`;

    this.sendAjaxRequest('post', url, data, questionId, answerIndex, (response) => {
      const addInformationLink = document.querySelector(idToQuery);
      if (addInformationLink) {
        addInformationLink.href = `/voters/answer_drafts/${response.answer_draft_id}/edit`;

      }
    });
  }

  private async updateAnswer(answerDraftId, data, questionId, answerIndex): void {
    const url = `/voters/answer_drafts/${answerDraftId}`;

    this.sendAjaxRequest('patch', url, data, questionId, answerIndex);
  }

  private sendAjaxRequest(method, url, data, questionId, answerIndex, successCallback = null) {
    const errorId = answerIndex ? `#question_${questionId}_error_container_${answerIndex}` : `#question_${questionId}_error_container`;
    const errorContainer = document.querySelector(errorId);

    Rails.ajax({
      url: url,
      type: method,
      data: new URLSearchParams(Object.entries(data)),
      success: function(response) {
        errorContainer.innerHTML = '';

        if (successCallback) {
          successCallback(response);
        }
      },
      error: function(response) {
        const errorMessages = response.errors;
        errorContainer.innerHTML = '';

        errorMessages.forEach(function(message) {
          const errorMessage = document.createElement('p');
          errorMessage.classList.add('help_text', 'error-message');
          errorMessage.textContent = message;
          errorContainer.appendChild(errorMessage);
        });
      }
    });
  }

  public handleButtonClick(): void {
    const buttonHref = this.addInfoButtonTarget.href;

    if (this.addInfoButtonTarget.classList.contains('programatic-click') && buttonHref.endsWith('edit')) {
      this.addInfoButtonTarget.classList.remove('programatic-click');
      return;
    }

    event.preventDefault();
    this.addInfoButtonTarget.disabled = true;
    const tempoDeEspera = 500;

    setTimeout(() => {
      this.addInfoButtonTarget.disabled = false;
      this.addInfoButtonTarget.classList.add('programatic-click');
      this.addInfoButtonTarget.click();
    }, tempoDeEspera);
  }
}
