import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['readStateContainer', 'changeStateContainer'];

  declare readStateContainerTarget: HTMLInputElement;
  declare changeStateContainerTarget: HTMLElement;

  public toggleContainer(_event: any): void {
    this.readStateContainerTarget.classList.add("hide");
    this.changeStateContainerTarget.classList.remove("hide");
  }
}
