import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'form',
    'skipPersistenceInput',
    'saveQuorumWinnersSettingsButton',
    'cancelQuorumWinnersSettingsButton',
    'pageSourceHiddenField',
    'buttonsGroupContainer',
    'errorMessage',
    'multiVoteWinnerDeclarationSelect',
    'winnerDeclarationInput'
  ]

  declare readonly formTarget: HTMLFormElement
  declare readonly skipPersistenceInputTarget: HTMLInputElement
  declare readonly saveQuorumWinnersSettingsButtonTarget: HTMLButtonElement
  declare readonly cancelQuorumWinnersSettingsButtonTarget: HTMLButtonElement
  declare readonly pageSourceHiddenFieldTarget: HTMLInputElement
  declare readonly buttonsGroupContainerTarget: HTMLDivElement
  declare readonly errorMessageTarget: HTMLDivElement
  declare readonly multiVoteWinnerDeclarationSelectTarget: HTMLSelectElement
  declare readonly winnerDeclarationInputTarget: HTMLInputElement

  connect(): void {
    this.element.addEventListener("turbo:submit-end", event => this.handleSubmitResponse(event))
  }

  updateView(event): void {
    this.errorMessageTarget.classList.add('hide')

    NProgress.start()
    this.skipPersistenceInputTarget.value = 'true'
    this.formTarget.requestSubmit()
  }

  selectMultiVoteWinnerDeclaration(event): void {
    if (this.multiVoteWinnerDeclarationSelectTarget.value == 'individual_thresholds'){
      this.winnerDeclarationInputTarget.value = ''
    } else {
      this.winnerDeclarationInputTarget.value = this.multiVoteWinnerDeclarationSelectTarget.value
    }
    
    this.updateView()
  }

  saveQuorumWinnersSettingsButton(event): void {
    event.preventDefault()

    NProgress.start()

    this.errorMessageTarget.classList.add('hide')
    this.buttonsGroupContainerTarget.classList.add('hide')
    this.skipPersistenceInputTarget.value = 'false'
    this.formTarget.requestSubmit()
  }

  cancelQuorumWinnersSettingsButton(event): void {
    event.preventDefault()

    NProgress.start()
    window.top.location = this.pageSourceHiddenFieldTarget.value
  }

  sanitizeTypedValue(event): void {
    const input = event.target as HTMLInputElement;

    if (input) {
      input.value = input.value.replace(/[^0-9]/g, '');
      if (input.classList.contains('percent-value') && parseInt(input.value, 10) > 100) {
        input.value = '100';
      }
    }
  }

  private handleSubmitResponse(event): void {
    if (this.skipPersistenceInputTarget.value === 'false') {
      if (!event.detail.success) {
        this.errorMessageTarget.classList.remove('hide')
        this.buttonsGroupContainerTarget.classList.remove('hide')
        NProgress.done()
      } else {
        window.top.location = this.pageSourceHiddenFieldTarget.value
      }
    } else {
      NProgress.done()
    }
  }
}