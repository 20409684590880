import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    "allowMultipleMembers",
    "multipleMembersCount",
    "pricingAndBillingUnlimitedCheckbox",
    "featuresCheckbox",
    "form",
    "submitButton"
  ];

  declare readonly allowMultipleMembersTarget: HTMLInputElement;
  declare readonly multipleMembersCountTarget: HTMLInputElement;
  declare readonly pricingAndBillingUnlimitedCheckboxTargets: HTMLInputElement[];
  declare readonly featuresCheckboxTargets: HTMLInputElement[];
  declare readonly formTarget: HTMLFormElement;
  declare readonly submitButtonTarget: HTMLButtonElement;

  private initialFormState: string;

  connect(): void {
    this.initialFormState = this.serializeForm(this.formTarget);
    this.updateExtraMembersLimit();
    this.checkFormModification();
  }

  allowMultipleUsersSwitch(): void {
    if (this.allowMultipleMembersTarget.checked) {
      this.multipleMembersCountTarget.readOnly = false;
    } else {
      this.multipleMembersCountTarget.value = '1';
      this.multipleMembersCountTarget.readOnly = true;
    }
  }

  updateExtraMembersLimit(): void {
    if (this.multipleMembersCountTarget.value === '') {
      this.multipleMembersCountTarget.value = '1';
    }
  }

  onPricingAndBillingUnlimitedAllClick(): void {
    this.pricingAndBillingUnlimitedCheckboxTargets.forEach(checkbox => {
      checkbox.checked = true;
    });
  }

  serializeForm(formElement: HTMLFormElement): string {
    return new URLSearchParams(new FormData(formElement)).toString();
  }

  checkFormModification(): void {
    const currentFormState = this.serializeForm(this.formTarget);
    this.submitButtonTarget.disabled = (this.initialFormState === currentFormState);
  }

  onEnableAllFeaturesClick(): void {
    this.featuresCheckboxTargets.forEach(checkbox => {
      checkbox.checked = true;
    });
    this.checkFormModification();
  }

  onEnableAllUnlimitedPricingAndBillingClick(): void {
    this.pricingAndBillingUnlimitedCheckboxTargets.forEach(checkbox => {
      checkbox.checked = true;
    });
    this.checkFormModification();
  }

  public confirmPrimaryOrganizationChange(event: Event): void {
    event.preventDefault();

    alert('Changing the primary organization will affect all organizations under it. Some organizations may have votes in progress. Proceed with caution.');
  }
}
