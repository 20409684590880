import { Controller } from 'stimulus';

export default class extends Controller {
  trigger(event: Event): void {
    const form = (event.target as HTMLElement).closest('form');

    if (form) {
      form.requestSubmit();
    }
  }

  sanitizeTypedValue(event: Event): void {
    const input = event.target as HTMLInputElement;

    if (input) {
      input.value = input.value.replace(/[^0-9]/g, '');
      if (input.classList.contains('percent-value') && parseInt(input.value, 10) > 100) {
        input.value = '100';
      }
    }
  }
}
