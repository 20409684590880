import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['stickyBar'];

  declare stickyBarTarget: HTMLElement;

  connect(): void {
    const stickyOffset = this.stickyBarTarget.offsetTop;
    window.onscroll = () => this.checkPageScroll(stickyOffset);
  }

  checkPageScroll(stickyOffset: number) {
    if (window.scrollY >= stickyOffset) {
      this.stickyBarTarget.classList.add("sticky")
    } else {
      this.stickyBarTarget.classList.remove("sticky");
    }
  }
}
